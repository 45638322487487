import React, {
  createContext, useContext, useState, useMemo, useEffect
} from "react";
import { taxEndpoint, termsEndpoint, reqOptionsGet } from "../lib/endpoints";

const CatContext = createContext({
  clouds: [],
  setClouds: () => { },
  menuOpen: false,
  setMenuOpen: () => { },
});

export default function CatProvider({ children }) {


  const [menuOpen, setMenuOpen] = useState(false);
  const [clouds, setClouds] = useState([]);


  useEffect(() => { // get clouds
    const getClouds = async () => {
      try {
        const cachedClouds = sessionStorage.getItem('clouds');

        if (cachedClouds ) {
          setClouds(JSON.parse(cachedClouds));
        } else {

          const response1 = await fetch(taxEndpoint, reqOptionsGet);
          const data = await response1.json();

          let twt = [];

          // Create an array of promises for each async fetch operation
          const fetchPromises = Object.keys(data).map(async (k) => {
            if (!(['location', 'daytime', 'genre', 'season', 'year', 'daytime'].includes(k)))
              return;

            const response2 = await fetch(termsEndpoint + k + '?_embed&acf&per_page=100', reqOptionsGet);
            const terms = await response2.json();

            if (terms?.length > 0) {
              twt.push({ slug: k, name: data[k].name, count: terms.length, terms: terms });
            }
          });
          await Promise.all(fetchPromises);
          if (twt?.length > 0) {
            setClouds(twt);
            sessionStorage.setItem('clouds', JSON.stringify(twt));
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    getClouds();
  }, []);

  const values = useMemo(() => ({
    clouds,
    setClouds,
    menuOpen,
    setMenuOpen,
  }), [menuOpen, clouds]); // check later

  return (
    <CatContext.Provider value={values}>
      {children}
    </CatContext.Provider>
  );

}

export const useCat = () => useContext(CatContext);

