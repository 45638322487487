import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import Neono from "./Neono";
import Layout from "../Layout";
import SingleTerms from './SingleTerms';
import { useLang } from "../contexts/LangContext";
import CF from './CF';
import Footer from "./Footer";
import "./Single.scss";
import { endpoint, reqOptionsGet, processOne } from "../lib/endpoints";



export default function Single() {

  const location = useLocation();
  console.log('location', location.pathname);
  let authorSlug, taxSlug, termId, id;

  if (location.pathname.split('/')[1] === 'ken') {
    authorSlug = location.pathname.split('/')[1];
    id = location.pathname.split('/')[2];
    console.log('authorSlug, id', authorSlug, id);
    taxSlug = '';
    termId = '';

  } else {

    const vars = useParams();
    authorSlug = vars.authorSlug;
    taxSlug = vars.taxSlug;
    termId = vars.termId;
    id = vars.id;

  }

  const { f, lang } = useLang();
  const [isGallery, setIsGallery] = useState(true);
  const [askOpen, setAskOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [one, setOne] = useState({});

  const [items, setItems] = useState([]);
  const [index, setIndex] = useState(0);


  const next = async () => {
    reset();
    setIndex(index + 1);
  }


  const prev = () => {
    reset();
    setIndex(index - 1);
  }

  const reset = () => {
    setIsGallery(true);
    setAskOpen(false);
  }


  const Prev = () => (
    items[index - 1] && authorSlug !== 'ken' ?
      <span className="button prev-button" tabIndex={0} onClick={() => prev()}>
        <Link to={`/${authorSlug}/${taxSlug}/${termId}/${items[index - 1]?.id}`}>
          <span className="material-symbols-outlined">chevron_left</span>
        </Link>
      </span>
      : <></>
  )

  const Next = () => (
    index === 0 || items[index + 1] && authorSlug !== 'ken' ?
      <span className="button next-button" tabIndex={0} onClick={() => next()}>
        <Link to={`/${authorSlug}/${taxSlug}/${termId}/${items[index + 1]?.id}`}>
          <span className="material-symbols-outlined">chevron_right</span>
        </Link>
      </span>
      : <></>
  )

  const Resize = () => (
    <span className="button image-fullsize-button" tabIndex={0} onClick={() => setIsGallery(!isGallery)}>
      <span className="material-symbols-outlined">{isGallery ? 'open_in_full' : 'close_fullscreen'}</span>
    </span>
  )


  useEffect(() => {
    const getOne = async () => {
      setLoading(true);
      try {
        const url = endpoint + `/${authorSlug}/${id}?_embed&acf`;
        const response = await fetch(url, reqOptionsGet);
        const o = await response.json();
        if (!o)
          return;

        const theOne = processOne(o);
        if (!theOne)
          throw new Error('No data');

        setOne(theOne);
        console.log('theOne', theOne);

      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getOne();
  }, [id, authorSlug]);

  useEffect(() => {
    const getItems = async () => {
      setLoading(true);
      try {
        let url;
        if (authorSlug === 'ken') {
          url = endpoint + `/${authorSlug}/?_embed&acf&per_page=20`;
        } else {
          url = endpoint + `/${authorSlug}/?_embed&acf&${taxSlug}=${termId}&per_page=20`;
        }
        const response = await fetch(url, reqOptionsGet);
        const data = await response.json();
        if (!Array.isArray(data) || data.length === 0)
          return;
        const theItems = data.map((p) => processOne(p));
        setItems(theItems);
        const ind = theItems.findIndex((i) => i.id === parseInt(id));
        setIndex(ind);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getItems();
  }, [id, authorSlug, taxSlug, termId]);

  return (
    loading ? <Neono /> : one && one.thumb ? (

      <Layout className={`single ${!isGallery ? ' full' : ''}`} >

        <div className="buttons">
          <Prev />
          <Resize />
          <Next />
        </div>


        <div className="work">

          <div className={`image-container`}>
            <ImageWithSpinner
              imageUrl={isGallery ? one.thumb.medium.source_url : one.thumb.large.source_url}
              alt={one.title[lang]}
              id={one.id}
              style={{ width: isGallery ? one.thumb.medium.width : one.thumb.large.width }}
            />
          </div>

          {isGallery && (
            <div className="Detail">
              <div className="inner">
                <div className="pa-de">
                  {one.title[lang]}
                  <span className="smaller"> {one.size && one.year ? one.size + ', ' + one.year : one.size ? one.size : one.year ? one.year : ''}</span>

                  <span className="buttons q">
                    <span className="button" tabIndex={10} onClick={() => setAskOpen(!askOpen)}>
                      <span className="material-symbols-outlined ">
                        email
                      </span>
                      {f('')}
                    </span>
                  </span>

                  <div className={`ask-form ${askOpen ? 'open' : ''}`}>
                    <CF layout={'h'} />
                  </div>

                </div>

                {one.terms && authorSlug === 'painting' && (
                  <SingleTerms terms={one.terms} />
                )}

                {one.content?.length > 100 && (
                  <div className="description">
                    {one.content}
                  </div>

                )}
              </div>
            </div>

          )}

        </div>

        <span className="edit-button"><a target="_blank" href={`https://paintings.kosnichev.ru/wp-admin/post.php?post=${one.id}&action=edit`}>♥</a></span>

        <Footer />
      </Layout >
    ) : console.log(error)
  )
}





export function ImageWithSpinner({ imageUrl, alt, id, style = {} }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleImageLoad = () => setIsLoading(false);
    const image = new Image();
    image.onload = handleImageLoad;
    image.src = imageUrl;

    return () => {
      // Clean up event listener (optional)
      image.onload = null;
    };
  }, [imageUrl]);

  return (
    <div>
      {isLoading ? (
        <Neono />
      ) : (
        <img
          src={imageUrl}
          alt={alt}
          id={id}
          style={style}
        />
      )}
    </div>
  );
}


