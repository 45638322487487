import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLang } from '../contexts/LangContext';
import { useCat } from '../contexts/CatContext';
import usePageType from '../hooks/usePageType';
import { Lang } from './Lang';
import './Menu.scss';

export default function Menu() {

  const { menuOpen, setMenuOpen } = useCat();
  const { f } = useLang();
  const pageType = usePageType();

  useEffect(() => {
    const handleEscKey = (event) => {
      if (menuOpen && event.key === 'Escape') {
        setMenuOpen(false);
      }
    };
    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [setMenuOpen]);

  return (

    <div className="menu">

      <span tabIndex={0} className="menu-item" onClick={() => setMenuOpen(!menuOpen)}>
        <Link to="/">
          {f('Картины')}
        </Link>
      </span>

      <span tabIndex={1} className="menu-item" onClick={() => setMenuOpen(!menuOpen)}>
        <Link to="/page/author">
          {f('Автор')}
        </Link>
      </span>

      <span tabIndex={1} className="menu-item" onClick={() => setMenuOpen(!menuOpen)}>
        <Link to="/page/exhibitions">
          {f('Выставки')}
        </Link>
      </span>

      <span tabIndex={2} className="menu-item" onClick={() => setMenuOpen(!menuOpen)}>
        <Link to="/page/contact">
          {f('Контакты')}
        </Link>
      </span>

      <span tabIndex={1} className="menu-item" onClick={() => setMenuOpen(!menuOpen)}>
        <Link to="/ken">
          {f('Отец')}
        </Link>
      </span>

      <span tabIndex={3} className="menu-item">
        <span className='inner'>{f('Выбрать язык')}</span> <Lang />
      </span>

    </div>
  );
}
