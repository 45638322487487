import React from 'react';
import { Link } from 'react-router-dom';
import { useLang } from '../contexts/LangContext';  
import { IconFb } from './Icons';
import './Footer.scss';

export default function Footer() {

  const { f } = useLang();

  return (
    <div className={`Footer`}>
      <div className="row">

        <span className="piece copy">
          © {new Date().getFullYear()} {f('Александр Косничев')}. {f('Все права защищены')}.
          <Link to="/page/legal">{f('Правовая информация')}</Link>  
          <Link to="/page/privacy">{f('Политика конфиденциальности')}</Link> 
          <Link to="/page/tos">{f('Условия использования')}</Link>        
          <Link to="/page/contact">{f('Контакты')}</Link> 
        </span>

        <span className="piece icon">
          <a href="https://www.facebook.com/alexander.kosnichev" target="_blank" rel="noreferrer">
            <IconFb />
          </a>
        </span>
        
      </div>
    </div>
  )
}

