import React from "react";
import { Link } from 'react-router-dom';
import { uid } from 'uid';
import { useLang } from '../contexts/LangContext';
import { useCat } from '../contexts/CatContext';
import './Clouds.scss';


const Clouds = () => {

  const { f } = useLang();
  const { clouds } = useCat();


  const Cloud = ({ cloud }) => {

    return (
      <div className="terms">
        {cloud?.terms.map((term, j) => (
          cloud.slug === 'genre' && j === 0 ? (
            <span className="rubric" key={j}>
              <h2>{f('Жанр')}</h2>
              <span className="item" key={j}>
                <Link to={`/painting/${cloud.slug}/${term.id}`}>{f(term.name)}</Link>
              </span>
            </span>
          ) : (
            <span className="item" key={j}>
              <Link to={`/painting/${cloud.slug}/${term.id}`}>{f(term.name)}</Link>
            </span>
          )
        ))}
      </div>
    )
  }

  const Genres = () => {
    return (
      <div className="cloud first" key={uid()}>
        <div className="cloud genre" key={uid()}>
          {clouds?.map((cloud, i) => cloud.slug === 'genre' ? (
            <Cloud cloud={cloud} key={i} />
          ) : null
          )}
        </div>
      </div>
    )
  }

  const Locations = () => {
    const { f } = useLang();

    return (
      <div className="cloud location">
        {clouds?.map((cloud, i) => cloud.slug === 'location' ? (
          <div key={i}>
            <h2>{f('Место')}</h2>
            <Cloud cloud={cloud} key={uid()} />
          </div>
        ) : null
        )}
      </div>
    )
  }

  const Seasons = () => {
    return (
      <div className="cloud season">
        {clouds?.map((cloud, i) => cloud.slug === 'season' ? (
          <div key={i}>
            <Cloud cloud={cloud} />
          </div>
        ) : null
        )}
      </div>
    )
  }

  const Daytime = () => {
    return (
      <div className="cloud daytime">
        {clouds?.map((cloud, i) => cloud.slug === 'daytime' ? (
          <div key={i}>
            <Cloud cloud={cloud} />
          </div>
        ) : null
        )}
      </div>
    )
  }

  const Years = () => {
    return (
      <div className="cloud year">
        {clouds?.map((cloud, i) => cloud.slug === 'year' ? (
          <Cloud cloud={cloud} key={i} />
        ) : null
        )}
      </div>
    )
  }


  return (
    <div className="sky" >
      <Genres />
      <div className="row">
        <Locations />
        <div className="time">
          <h2>{f('Время')}</h2>
          <Seasons />
          <Daytime />
          <Years />
        </div>
      </div>
    </div>
  )
}

export default Clouds;

