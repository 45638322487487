import React from "react";
import { useLang } from "../contexts/LangContext";
import Layout from '../Layout';
import Wrapper from "./Wrapper";
import FrontHeader from "./FrontHeader";
import Works from "./Works";
import KenBio from "./KenBio";
import './Ken.scss';

import './Front.scss';


export default function Ken() {

  const { f } = useLang();

 return (
    <Layout>
      <div className={`Front ken`}>
        <Wrapper className="prima">
          <FrontHeader title={f('Работы Евгения Косничева')}/>
        </Wrapper>
        
        <Wrapper className="secunda">
          <Works authorSlug={'ken'} taxSlug={''} termId={''} perPage={10} />
        </Wrapper>

        <Wrapper className="tertia">
          <KenBio />
        </Wrapper>
   

      </div>
    </Layout>
  )
}
