import React from "react";
import { Link } from "react-router-dom";
import { useLang } from "../contexts/LangContext";


export default function SingleTerms ({ terms }) {

  const { f } = useLang();
  
  return (
    terms ?
    <div className="terms">
    {terms?.map((t, i) => (
      <span key={i} className="item"><Link to={`/paintings/${t.taxSlug}/${t.termId}`}>{f(t.name)}</Link></span>
    ))}
  </div>
  : <></>
  );
}
