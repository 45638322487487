import React from 'react';
import TopBar from "./components/TopBar";
import Footer from "./components/Footer";
import Wrapper from './components/Wrapper';
import usePageType from './hooks/usePageType';
import './Layout.scss';


export default function Layout({ className, style, children }) {

  const pageType = usePageType();

  return (
    <div className="page">
      <Wrapper className={`main ${className ?? ''}`} style={style ?? ''}>
        <TopBar />
        {children}
      </Wrapper>
    {pageType !== 'single' && <Footer />}
    </div>
  )
}
