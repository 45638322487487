export const domain = 'https://paintings.kosnichev.ru';
export const tokenEndpoint = domain + '/wp-json/jwt-auth/v1/token';
export const mediaEndpoint = domain + '/wp-json/wp/v2' + '/media';
export const picsEndpoint  = domain + '/wp-json/wp/v2' + '/painting';
export const worksEndpoint = domain + '/wp-json/wp/v2' + '/painting';
export const termsEndpoint = domain + '/wp-json/wp/v2' + '/';
export const taxEndpoint   = domain + '/wp-json/wp/v2' + '/taxonomies';
export const pagesEndpoint = domain + '/wp-json/wp/v2' + '/pages';

export const kensEndpoint = domain + '/wp-json/wp/v2' + '/ken';

export const endpoint = domain + '/wp-json/wp/v2';



export const reqOptionsGet = {
  method: 'GET',
  headers: { 
    'Content-Type': 'application/json',
  },
}

export const reqOptionsPost = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
  }
}


import parse from 'html-react-parser';


export const processOne = (one) => {


  if (!one || !one['_embedded'] ) 
    return {};

  const terms = one['_embedded']['wp:term'] 
    ? one['_embedded']['wp:term'].map(t => t.map( tt => ({ name: tt.name, slug: tt.slug, taxSlug: t[0].taxonomy, termId: tt.id }))).flat()
    : [];
  const th = one['_embedded']['wp:featuredmedia'] 
   ? one['_embedded']['wp:featuredmedia'][0].media_details.sizes
    : {};
    
  const thumb = th ? {
    thumbnail: th.thumbnail,
    medium: th.medium ?? th.full,
    large: th.large ?? th.full,
    full: th.full,
  } : {};
  
  const title = {
    ru: one.title.rendered ?? '',
    en: (one.acf.title_en || one.title.rendered) ?? '',
  }
  const size = one.acf.size ?? '';
  const content = parse(one.content.rendered) ?? '';
  const year = one.acf.year ?? '';
  const pid = one.id;

  const item = {
    terms,
    thumb,
    title,
    content,
    size,
    year,
    id: pid,
  };

return item;
}


export const login = async (username, password) => {
  try {
    const response = await fetch(tokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Basic' + btoa(media + ':' + process.env.REACT_APP_WP_MEDIA_KEY),
      },
      body: JSON.stringify({ username: username, password: password }), 
    });

    if (!response.ok) {
      console.log("Login error: ", response.statusText);
      return false;
    }

    const data = await response.json();

    localStorage.setItem('token', data.token);
    return true;

  } catch (error) {
    console.log("Login error: ", error);
    return false;
  }

};

