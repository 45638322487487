import React from "react";
import { useLang } from "../contexts/LangContext";
import './FrontHeader.scss';

export default function FrontHeader({ title = ''}) {

  const { f } = useLang();

  return ( 
    <div className={`FrontHeader`}>
      <div className="header">
        <h1>
          {title !== '' ? f(title) : f('Картины Александра Косничева')}
        </h1>
      </div>
    </div>
  );
}