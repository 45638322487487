import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "./components/NotFound";
import Front from "./components/Front";
import Term from "./components/Term";
import Single from "./components/Single";
import Ken from "./components/Ken";
import StaticPage from "./components/StaticPage";

export default function App() {


  return (
    <Routes>
      <Route path="*" element={<NotFound />} />

      <Route path="/" element={<Front />} />
      <Route path="/ken" element={<Ken />} />
      <Route path="/ken/:id" element={<Single />} />
      <Route path="/:authorSlug" element={<Front />} />
      <Route path="/:authorSlug/:taxSlug" element={<Front />} />

      <Route path="/:authorSlug/:taxSlug/:termId" element={<Term />} />

      <Route path="/:authorSlug/:taxSlug/:termId/:id" element={<Single />} />
      <Route path="/:authorSlug/:id" element={<Single />} />

      <Route path="/page/:pageSlug" element={<StaticPage />} />
    </Routes>
  );
}
