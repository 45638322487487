import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FrontHeader from './FrontHeader';
import Works from './Works';
import Layout from '../Layout';
import Wrapper from './Wrapper';
import Clouds from './Clouds';
import { useLang } from '../contexts/LangContext';
import { useCat } from '../contexts/CatContext';
import './Works.scss';


function Term() {

  const { taxSlug, termId } = useParams();
  const { f } = useLang();
  const {  clouds } = useCat();
  const [termName, setTermName] = React.useState('');

  useEffect(() => {
    if (taxSlug && termId && clouds && clouds?.length > 0) {
      let tax = clouds.find(cloud => cloud.slug === taxSlug); 
      if (tax?.terms.length > 0) {
        let trm = tax.terms.find(t => t.id == termId);
        if (trm) {
          setTermName(trm.name);
        }
     }
    }
  }, [taxSlug, termId]);


  return (
    <Layout>
      <div className={`Term`}>
        <Wrapper className="prima">
          {termName && <FrontHeader title={ <><span>{f('Картины с тегом ')}</span><span className='highlighted'>{f(termName)}</span></> } className={'smaller'} />}
        </Wrapper>

        <Wrapper className="secunda">
          <Works authorSlug={'painting'}  taxSlug={taxSlug} termId={termId} perPage={10}/>
        </Wrapper>

        <Wrapper className="tertia">
          <Clouds />
        </Wrapper>

      </div>
    </Layout>
  );
}

export default Term;
