import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { pagesEndpoint } from "../lib/endpoints";
import Layout from "../Layout";
import { useLang } from "../contexts/LangContext";
import { SocialIcon } from 'react-social-icons';
import './StaticPage.scss';
import './FrontHeader.scss';
import CF from "./CF";
import Neono from "./Neono";


export default function StaticPage() {

  const { lang, f } = useLang();
  const [page, setPage] = useState({});
  const { pageSlug } = useParams();

  const getPage = async () => {
    try {
      const str = `${pagesEndpoint}/?slug=${pageSlug}-${lang}`;
      const response = await fetch(str);
      const data = await response.json();
      setPage(data[0]);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    (async () => {
      await getPage();
    })();
  }, [pageSlug, lang]);

  return (
    page && page.title && page.title.rendered ?
      <Layout className={`static-page`}>


        <div className="static__imported">


          <div className={`static ${pageSlug}`}>
          {parse(`${page?.content?.rendered}`)}
            {pageSlug === 'contact' && (
                <CF />
            )}
          </div>
        </div>
      </Layout>
      : <Neono />
  )
}
