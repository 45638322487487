import React from 'react';
import './Lang.scss';
import { useLang } from '../contexts/LangContext';
import { useCat } from '../contexts/CatContext';

export const Lang = () => {

  const { lang, setLang } = useLang();
  const { setMenuOpen } = useCat();

  return (
    <select className="select-lang" onChange={(e) => {
        setLang(e.target.value);
        setMenuOpen(false);
      }} value={lang}
    >
      <option value="en">English</option>
      <option value="ru">Русский</option>
    </select>
  )
}
