import React, { useState, useEffect } from "react";
import { worksEndpoint, kensEndpoint, reqOptionsGet, processOne } from "../lib/endpoints";
import useScreenSize from "../hooks/useScreenSize";
import Thumb from "./Thumb";
import './Works.scss';
import Neono from "./Neono";
import { toast } from "react-toastify";


export default function Works({ authorSlug, taxSlug, termId, perPage }) {

  const [pics, setPics] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [pg, setPg] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const endpoint = authorSlug === 'ken' ? kensEndpoint : worksEndpoint;
  const { w } = useScreenSize();
  const [alts, setAlts] = useState([]);
  const [index, setIndex] = useState(0);
  const [prevIdx, setPrevIdx] = useState(0);


  const getPics = async (tar = 'pics') => {
    if (tar === 'pics' && !authorSlug && !taxSlug && !termId) {
      return;
    } else if (tar === 'alts' && w < 1200) {
      return;
    }

    try {
      let url;
      if (tar === 'pics') {
        setLoading(true);
        url = endpoint + '/?_embed&acf&per_page=' + perPage + '&page=' + pg + '&' + taxSlug + '=' + termId + '&orderby=menu_order&order=desc';
      } else if (tar === 'alts') {
        url = endpoint + '/?_embed&acf&per_page=20&orderby=rand';
      }

      const response = await fetch(url, reqOptionsGet);
      const works = await response.json();
      if (!Array.isArray(works) || works.length === 0)
        return;
      const thePics = works.map((p) => processOne(p));

      if (tar === 'pics') {
        setHasMore(thePics.length === perPage);
        setPics(thePics);

      } else if (tar === 'alts' ) {
        return thePics;
      }
    }

    catch (error) {
      toast.error('Network error. Please try again later.');
    } finally {
      tar === 'pics' && setLoading(false);
    }

  }

  useEffect(() => {
    (async () => {
      await getPics('pics');
    })();
  }, [authorSlug, taxSlug, termId, pg, perPage]);

  useEffect(() => { // usem arrows to navigate
    window.addEventListener('keydown', (e) => {
      if (e.key === 'ArrowLeft' && pg > 1) {
        handlePage('prev');
      } else if (e.key === 'ArrowRight' && hasMore) {
        handlePage('next');
      }
    }
    );
    return () => {
      window.removeEventListener('keydown', (e) => {
        if (e.key === 'ArrowLeft' && pg > 1) {
          setPg(pg - 1);
        } else if (e.key === 'ArrowRight' && hasMore) {
          setPg(pg + 1);
        }
      });
    }
  }, [pg, hasMore]);


  const handlePage = (direction) => {
    if (direction === 'prev' && pg > 1) {
      setPg(pg - 1);
    } else if (direction === 'next' && hasMore) {
      setPg(pg + 1);
    }
  }

  useEffect(() => {
    (async () => {
      const alts = await getPics('alts');
      setAlts(alts);
    })();
  }, []);

  const moveImages = () => {

    if (!alts || alts.length === 0 || !pics || pics.length === 0) 
      return;
    
    console.log('animating...');


    const randIndex = Math.floor(Math.random() * pics.length);
    if (randIndex === prevIdx) {
      setIndex(prevIndex => prevIndex + 1);
      return
    }
    setPrevIdx(randIndex);
    const prevImage = pics[randIndex];

    const nextImage = alts[index];

    if (pics.find(p => p.id === alts[index].id)) {
      setIndex(prevIndex => prevIndex + 1);
      return;
    }

    const picsWithoutPrev = pics.filter(p => p.id !== prevImage.id);

    picsWithoutPrev.splice(randIndex, 0, nextImage);
    picsWithoutPrev.map(p => p.cls = 'regular');
    picsWithoutPrev[randIndex].cls = `alt`;
    setPics(picsWithoutPrev);

    if (index < alts.length - 1)
      setIndex(prevIndex => prevIndex + 1);
    else
      setIndex(0);
  }


  useEffect(() => {
    // if (!alts || alts.length === 0 || !pics || !pics.length == 0 || w < 1200) 
    //   return;

    if (w < 1200)
      return;

    if (alts.length === 0 || pics.length === 0) 
      return;

    if (!alts[index])
      return;

      console.log('setting interval...');

    const id = setInterval(() => moveImages(), 3000);
    return () => clearInterval(id);
  }, [pics, alts, w]);


  return (
    loading ? <Neono /> : 
    <div className="Works">
      <div className={`per-row-5`}>
        {pics.length > 0
          ? pics?.map((p, idx) =>
            <Thumb key={idx} p={p} taxSlug={taxSlug} termId={termId} authorSlug={authorSlug} />
          )
          : <div className="no-results">No results</div>
        }

      </div>

      <div className="pagination">
        <div className="pagination--inner">

          {pg > 1 ? (
            <span className={`page-btn prev-page`} tabIndex={0} onClick={() => handlePage('prev')}>
              <span className="material-symbols-outlined">
                arrow_back
              </span>
            </span>
          ) : (<span className={`page-btn prev-page disabled`}>
          </span>
          )}


          {hasMore ? (
            <span className="page-btn next-page" tabIndex={2} onClick={() => handlePage('next')}>
              <span className="material-symbols-outlined">
                arrow_forward
              </span>
            </span>
          ) : (<span className={`page-btn next-page disabled`}>
          </span>
          )}

        </div>
      </div>

    </div>
  )
}

