import React, { useState } from 'react';
import { useLang } from '../contexts/LangContext';
import DOMPurify from 'dompurify';
import './CF.scss';

export default function CF ( { layout='v' }) {

  const { f } = useLang();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });


  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

   
  const sendEmail = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setStatusMessage('Sending...');

    if (!formData.name || !formData.email || !formData.phone || !formData.message) {
      setStatusMessage(f('Пожалуйста, заполните все поля.'));
      return;
    }
    if (!formData.email.includes('@')) {
      setStatusMessage(f('Пожалуйста, введите корректный адрес электронной почты.'));
      return;
    }
    if (formData.phone.length < 9) {
      setStatusMessage(f('Пожалуйста, введите корректный номер телефона.'));
      return;
    }
    if (formData.message.length < 10) {
      setStatusMessage(f('Пожалуйста, введите сообщение длиной не менее 10 символов.'));
      return;
    }
    if (formData.message.length > 1000) {
      setStatusMessage(f('Сообщение не должно превышать 1000 символов.'));
      return;
    }
    if (formData.name.length > 100) {
      setStatusMessage(f('Имя не должно превышать 100 символов.'));
      return;
    }
    if (formData.email.length > 100) {
      setStatusMessage(f('Электронная почта не должна превышать 100 символов.'));
      return;
    }
    if (formData.phone.length > 100) {
      setStatusMessage(f('Телефон не должен превышать 100 символов.'));
      return;
    }
    
    const url = 'https://api.brevo.com/v3/smtp/email';
    const emailData = {
      sender: {
        name: 'A. Kosnichev',
        email: 'a@paintings.kosnichev.ru'
      },
      to: [
        {
          email: 'a.kosnichev@gmail.com',
          name: 'A. Kosnichev'
        }
      ],
      subject: 'New message from website',
      htmlContent: DOMPurify.sanitize(`<html><head></head><body><p>Hello,</p>This is my first transactional email sent from Brevo.</p>
      <p>Name: ${formData.name} 
      <br /><br />
      Email:${formData.email} 
      <br /><br />
      Phone: ${formData.phone}
      <br /><br />
      Message:  ${formData.message}
      </p>
      </body></html>`),
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'api-key': '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',
        },
        body: JSON.stringify(emailData)
      });

      if (response.ok) {
        setStatusMessage(f('Сообщение успешно отправлено.'));
      } else {
        setStatusMessage(f('При отправке сообщения возникла ошибка. Попробуйте еще раз или вернитесь позже.'));
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setStatusMessage(f('При отправке сообщения возникла ошибка. Попробуйте еще раз или вернитесь позже.'));
    }
  };

  
  return (
    <form className={`cf ${layout}`} onSubmit={sendEmail}>

      <div className='form-row'>
        <label htmlFor="name">
          <span>{f('Имя')}</span>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </label>
      </div>

      <div className='form-row'>
        <label htmlFor="email">
        <span>{f('Э.почта')}</span>
          <input type="text" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </label>
      </div>

      <div className='form-row'>
        <label htmlFor="phone">
        <span>{f('Тел.')}</span>
          <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
        </label>
      </div>

      <div className='form-row textarea'>
        <label htmlFor="message">
        <span>{f('Ваше сообщение')}</span>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} required />
        </label>
      </div>

      <div className='form-row bttn'>
      <button className="mighty" type="submit">{f('Отправить')}</button>
      {statusMessage && <span className='msg'>{statusMessage}</span>}
      </div>
    </form>
  );
};



