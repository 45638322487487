import React from "react";
import { Link } from 'react-router-dom';
import { useLang } from '../contexts/LangContext';
import './Thumb.scss';

const Thumb = ({ p, authorSlug, taxSlug, termId }) => {

  const { lang } = useLang();
  const link = `/${authorSlug}${taxSlug ? '/' + taxSlug : ''}${termId ? '/' + termId : ''}/${p.id}`;

  return (
    p && lang ?
      <div className={`thumb ${p.cls ?? ''}`}>
        <Link to={link}>
          <img src={p.thumb.medium.source_url} alt={p.title[lang]} />
        </Link>
        <span className={`thumb__title`}>
          <Link to={link}>
            {p.title[lang]}
          </Link>
        </span>
      </div>
      : null
  )
}

export default Thumb;
