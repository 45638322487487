export const laNames = {
  ru: 'Русский',
  en: 'English',
}

export const front = {
  ru: [
    { a: 'Картины Александра Косничева' },
    { b: 'Александр Косничев — московский художник, работающий в традициях русского реализма. '},
    { c: 'Картины Александра Косничева выставлялись в России, Европе, США и находятся в частных коллекциях во многих странах мира. '},
    { d: 'Выставки'},
    { e: 'Контакт'},
    { f: 'Картины с тегом '},
    { g: 'Александр Косничев' },
    // terms
    { h: 'Городской пейзаж' },
    { i: 'Деревенский пейзаж' },
    { j: 'Жанровая работа' },
    { k: 'Монастырский пейзаж' },
    { l: 'Морской пейзаж' },
    { m: 'Натюрморт' },
    { n: 'Пейзаж' },
    { o: 'Портрет' },
    { p: 'Академичка' },
    { q: 'Андроников монастырь' },
    { r: 'Борисоглеб' },
    { s: 'Валаам' },
    { t: 'Владимир' },
    { u: 'Волга' },
    { v: 'Гастингс' },
    { w: 'Гурзуф' },
    { x: 'Крым' },
    { y: 'Ладога' },
    { z: 'Ладожское озеро' },
    { aa: 'Москва' },
    { ab: 'Новгород' },
    { ac: 'о.Кефалония' },
    { ad: 'о.Корфу' },
    { ae: 'озеро Неро' },
    { af: 'Париж' },
    { ag: 'Петровский бульвар' },
    { ah: 'Пирей' },
    { ai: 'Плес' },
    { aj: 'Подозерка' },
    { ak: 'Покровский бульвар' },
    { al: 'Рождественский бульвар' },
    { am: 'Романов-Борисоглебск' },
    { an: 'Ростов' },
    { ao: 'Ростов-Великий' },
    { ap: 'с.Деболовское' },
    { aq: 'Соколиное' },
    { ar: 'Тверская область' },
    { as: 'Тутаев' },
    { at: 'Хопылево' },
    { au: 'Черное море' },
    { av: 'Яузские ворота' },
    { aw: 'Яузский бульвар' },
    { ax: 'Весна' },
    { ay: 'Зима' },
    { az: 'Лето' },
    { ba: 'Осень' },
    { bb: 'Поздняя осень' },
    { bc: 'Ранняя весна' },
    { bd: 'Место' },
    { be: 'Время' },
    { bf: 'День' },
    { bg: 'Ночь'},
    { bh: 'Утро'},
    { bi: 'Вечер'},
    { bj: 'Полдень'},
    { bk: 'Закат'},
    { bl: 'Рассвет'},
    { bm: 'Пасмурный день'},
    { bn: 'Солнечный день'},
    { bo: 'Раннее утро'},
    { bp: 'Поздний вечер'},
    { bq: 'Ранний вечер'},
    { br: 'Солнечное утро'},
    { bs: 'Солнечный вечер'},
    { bt: 'Солнечный день'},
    { bu: 'Сумерки'},
    { bv: 'Картины'},
    { bw: 'Жанр'},
    { bx: 'см' },
    { by: 'Автор'},
    { bz: 'Отправить сообщение'},
    { ca: 'Имя'},
    { cb: 'Э.почта'},
    { cc: 'Тел.'},
    { cd: 'Ваше сообщение'},
    { ce: 'Отправить'},
    { cf: 'Сообщение успешно отправлено'},
    { cg: 'При отправке сообщения возникла ошибка. Попробуйте еще раз или вернитесь позже.'},
    { ch: 'Выбрать язык'},
    { ci: 'Доступность'},
    { cj: 'Правовая информация' },
    { ck: 'Политика конфиденциальности' },
    { cl: 'Условия использования' },
    { cm: 'Контакты'},
    { cn: 'Все права защищены' },
    { co: 'Евгений Никанорович Косничев' },
    { cp: 'Картины Евгения Косничева' },
    { cq: 'Отец'},
    { cr: 'Работы Евгения Косничева'}
  ],
  en: [
    { a: 'Alexander Kosnichev Paintings' },
    { b: 'Alexander Kosnichev is a Moscow artist working in the traditions of Russian realism. '},
    { c: 'Paintings by Alexander Kosnichev have been exhibited in Russia, Europe, the USA and are in private collections in many countries of the world. '},
    { d: 'Exhibitions'},
    { e: 'Contact'},
    { f: 'Paintings tagged '},
    { g: 'Alexander Kosnichev' },
    // terms
    { h: 'Cityscape' },
    { i: 'Rural Landscape' },
    { j: 'Genre work' },
    { k: 'Monastery landscape' },
    { l: 'Seascape' },
    { m: 'Still life' },
    { n: 'Landscape' },
    { o: 'Portrait' },
    { p: 'Academician' },
    { q: 'Andronikov Monastery' },
    { r: 'Borisogleb' },
    { s: 'Valaam' },
    { t: 'Vladimir' },
    { u: 'Volga' },
    { v: 'Hastings' },
    { w: 'Gurzuf' },
    { x: 'Crimea' },
    { y: 'Ladoga' },
    { z: 'Ladoga Lake' },
    { aa: 'Moscow' },
    { ab: 'Novgorod' },
    { ac: 'Kefalonia Island' },
    { ad: 'Corfu Island' },
    { ae: 'Nero Lake' },
    { af: 'Paris' },
    { ag: 'Petrovsky Boulevard' },
    { ah: 'Piraeus' },
    { ai: 'Dance' },
    { aj: 'A subpoena' },
    { ak: 'Pokrovsky Boulevard' },
    { al: 'Rozhdestvensky Boulevard' },
    { am: 'Romanov-Borisoglebsk' },
    { an: 'Rostov' },
    { ao: 'Rostov-Veliky' },
    { ap: 'Debolovskoe' },
    { aq: 'Sokolinoe' },
    { ar: 'Tver region' },
    { as: 'Tutaev' },
    { at: 'Khopylevo' },
    { au: 'Black Sea' },
    { av: 'Yauza Gate' },
    { aw: 'Yauzsky Boulevard' },
    { ax: 'Spring' },
    { ay: 'Winter' },
    { az: 'Summer' },
    { ba: 'Autumn' },
    { bb: 'Late Autumn' },
    { bc: 'Early Spring' },
    { bd: 'Place' },
    { be: 'Time' },
    { bf: 'Day' },
    { bg: 'Night'},
    { bh: 'Morning'},
    { bi: 'Evening'},
    { bj: 'Noon'},
    { bk: 'Sunset'},
    { bl: 'Dawn'},
    { bm: 'Cloudy day'},
    { bn: 'Sunny day'},
    { bo: 'Early morning'},
    { bp: 'Late evening'},
    { bq: 'Early evening'},
    { br: 'Sunny morning'},
    { bs: 'Sunny evening'},
    { bt: 'Sunny day'},
    { bu: 'Twilight'},
    { bv: 'Paintings'},
    { bw: 'Genre'},
    { bx: 'см' },
    { by: 'Author'},
    { bz: 'Send message'},
    { ca: 'Name'},
    { cb: 'Email'},
    { cc: 'Phone'},
    { cd: 'Your message'},
    { ce: 'Send'},
    { cf: 'Message sent successfully'},
    { cg: 'An error occurred while sending the message. Try again or come back later.'},
    { ch: 'Language'}, 
    { ci: 'Availability'},
    { cj: 'Legal information' },
    { ck: 'Privacy Policy' },
    { cl: 'Terms of Use' },
    { cm: 'Contacts'},
    { cn: 'All rights reserved' },  
    { co: 'Evgeny Nikanorovich Kosnichev' },
    { cp: 'Paintings by Evgeny Kosnichev' },
    { cq: 'My Father'},
    { cr: 'Works by Evgeny Kosnichev'}

  ],
};

