import React from "react";
import { Link } from "react-router-dom";
import { useCat } from "../contexts/CatContext";
import Menu from './Menu';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './TopBar.scss';


export default function TopBar() {

  const { menuOpen, setMenuOpen } = useCat();

  return (

    <>
      <ToastContainer />

      <div className={`topbar${menuOpen ? ' active' : ''}`}>
        <div className="topbar--inner">

          <span className="logo">
            <Link to="/">ak</Link>
          </span>

          <span className={`burger`}>
            <i className={`material-symbols-outlined`} onClick={() => setMenuOpen(!menuOpen)}> {menuOpen ? 'close' : 'menu'}</i>
          </span>
        </div>
      </div>
      {menuOpen && <Menu />}
    </>
  )
}
