import React from "react";
import Layout from '../Layout';
import Wrapper from "./Wrapper";
import FrontHeader from "./FrontHeader";
import Works from "./Works";
import Clouds from "./Clouds";
import './Front.scss';

export default function Front() {   
  
  return (
    <Layout>
      <div className={`Front`}>
        <Wrapper className="prima">
          <FrontHeader />
        </Wrapper>
        
        <Wrapper className="secunda">
          <Works authorSlug={'painting'} taxSlug={`stuff`} termId={106} perPage={5} />
        </Wrapper>
   
        <Wrapper className="tertia">
          <Clouds />
        </Wrapper>

      </div>
    </Layout>
  )
}
