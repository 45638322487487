import React from 'react';
import { useLang } from '../contexts/LangContext';
import ken from '../assets/images/ken.jpg';
import ReactMarkdown from 'react-markdown';

export default function KenBio() {

  const { lang } = useLang();

  const ken_ru = `
  Евгений Никанорович Косничев родился в Подмосковье, в Люблино, 
  в 1929 году. Рано увлекшись рисованием, он в 15 лет начал 
  заниматься в заводской изостудии. В 1950 году, окончив Московское 
  областное художественное училище памяти 1905 года, он поступил в
  Московский государственный художественный институт им. В. И. Сурикова 
  на графический факультет (мастерская Е. Кибрика). Сразу же по 
  окончании института Евгений активно включается в художественную жизнь столицы. 
  С 1957 года, когда его произведения были приняты на Всесоюзную выставку, 
  Е. Косничев был постоянным участником крупнейших экспозиций страны. 

  Член Союза художников России с 1963 года.  Участник выставок «Советская Россия» 
  (1965‚ 1968, 1970, 1975, 1980, 1985, 1991), «В едином строю» (1964, 1967), 
  «Центр. Северные области» (1969, 1974), «Подмосковье» (1980, 1984, 1990), 
  «Подмосковье мое» в ЦВ3 (1968), «30 лет Победы» (1975), «25 лет МООСХ» (1972), 
  «45 лет Победы в Великой Отечественной войне» (1990), юбилейная выставка «50 лет МООСХ» (1995) и др. 

  В первые годы самостоятельной работы художник занимался книжной графикой, 
  впоследствии он надолго обращался к станковой графике. 
  Излюбленной техникой Е. Косничева был офорт, выступал он и как живописец. 

  Художник постоянно стремился обогатить свое знание жизни, увидеть ее во
  всей полноте и многообразии. Он много ездит: в Прибалтику, на
  Кавказ, в Крым, Сибирь, Среднюю Азию, в старинные русские города.
  Он писал на Севере, на берегах Баренцева моря, в Кижах, Мурманске, Кеми. 
  Все это нашло отражение в его работах.`;

  const ken_en = `
  Evgeny Nikanorovich Kosnichev was born in the Moscow region, in Lyublino,
  in 1929. Having become interested in drawing early, at the age of 15 he began
  study in the factory art studio. In 1950, after graduating from Moscow
  regional art school in memory of 1905, he entered
  Moscow State Art Institute named after. V. I. Surikova
  to the Faculty of Graphics (workshop of E. Kibrik). Immediately after
  After graduating from the institute, Evgeniy is actively involved in the artistic life of the capital.
  Since 1957, when his works were accepted for the All-Union Exhibition,
  E. Kosnichev was a regular participant in the country's largest exhibitions.

  Member of the Union of Artists of Russia since 1963. Participant of the exhibitions “Soviet Russia”
  (1965‚ 1968, 1970, 1975, 1980, 1985, 1991), “In Unity” (1964, 1967),
  "Center. Northern regions" (1969, 1974), "Moscow region" (1980, 1984, 1990),
  “My Moscow Region” in CV3 (1968), “30 Years of Victory” (1975), “25 Years of MOOSKH” (1972),
  “45 years of Victory in the Great Patriotic War” (1990), anniversary exhibition “50 years of MOOSH” (1995), etc.

  In the first years of independent work, the artist was engaged in book graphics,
  Subsequently, he turned to easel graphics for a long time.
  E. Kosnichev’s favorite technique was etching, and he also acted as a painter.

  The artist constantly sought to enrich his knowledge of life, to see it in
  in all its fullness and diversity. He travels a lot: to the Baltic states, to
  The Caucasus, Crimea, Siberia, Central Asia, ancient Russian cities.
  He wrote in the North, on the shores of the Barents Sea, in Kizhi, Murmansk, Kemi.
  All this is reflected in his works.`;

  return (
    <div className={`ken-bio`}>
      <div className='ken-photo'>
        <img src={ken} alt="Evgeny Kosnichev" />
      </div>
      <div className='ken-text'>
        <ReactMarkdown>
          {lang === 'ru' ? ken_ru : ken_en}
        </ReactMarkdown>
      </div>
    </div>
  )
}